<template>
  <div class="uk-container uk-container-expand">
    <div class="uk-grid uk-child-width-1-1" data-uk-grid>
      <div>
        <div class="uk-flex uk-flex-between uk-flex-middle">
          <div>
            <h3 class="uk-h3">
              <span data-uk-icon="icon: wordpress;ratio: 1.5"></span> Blog
            </h3>
          </div>
          <div>
            <router-link :to="{name: 'blog-edit'}" class="uk-button uk-button-primary">
              <span uk-icon="plus-circle"></span>
              Beitrag erstellen
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-grid" data-uk-grid>
      <div class="uk-width-1-1">
        <div class="uk-card uk-card-default uk-card-small">
          <div class="uk-card-body">
            <table class="uk-table uk-table-hover uk-table-divider">
              <thead>
                <tr>
                  <th class="uk-table-expand">Titel</th>
                  <th class="uk-width-small">Slug / Pfad</th>
                  <th class="uk-width-small uk-text-center">Status</th>
                  <th class="uk-width-small"></th>
                </tr>
              </thead>
              <tbody>
                <tr class="uk-visible-toggle" v-for="post in blogPosts" :key="post.id">
                  <td class="uk-table-link">
                    <router-link
                          class="uk-link-reset" :to="{name: 'blog-edit', params:{id: post.id}}" >
                    {{ post.title }}
                    </router-link>
                  </td>
                  <td class="uk-table-link">
                    <router-link
                          class="uk-link-reset" :to="{name: 'blog-edit', params:{id: post.id}}" >
                    {{ post.slug }}
                    </router-link></td>
                  <td class="uk-text-center">
                    <template v-if="post.active">
                      <span uk-icon="check" class="uk-text-success uk-icon"></span>
                    </template>
                    <template v-else>
                     <span uk-icon="ban" class="uk-text-danger uk-icon"></span>
                    </template>
                  </td>
                  <td>
                    <ul class="uk-invisible-hover uk-iconnav">
                      <li>
                        <router-link
                          class="uk-text-warning uk-icon"
                          uk-icon="icon: pencil" :to="{name: 'blog-edit', params:{id: post.id}}" uk-tooltip="Bearbeiten"/>
                      </li>
                      <li>
                       <a class="uk-icon" href="#" uk-icon="icon: link" target="blank" uk-tooltip="Vorschau" ></a>
                      </li>
                      <li>
                        <a
                          class="uk-text-danger uk-icon"
                          href="#"
                          uk-icon="icon: trash"
                          uk-tooltip="Löschen"
                          @click="remove(post.id)"
                        ></a>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageService from '@/services/page.service'
import "izitoast/dist/css/iziToast.min.css";
import iziToast from "izitoast/dist/js/iziToast.min.js";
import { mapGetters, mapActions } from 'vuex'
export default {
  data () {
    return {
      //
    }
  },
  mounted () {
    this.$store.dispatch('blog/getList')
  },

  computed: {
    ...mapGetters({
      blogPosts: 'blog/blogPosts'
    })
  },

  methods: {
    ...mapActions({
      getList: 'blog/getList'
    }),
    remove(post) {
      const $this = this
      this.$uikit.modal.confirm('<strong>Beitrag wirklich löschen?</strong></p>', {
        labels: {
          cancel: 'Abrechen',
          ok: 'Löschen'
        }
      }).then(function() {
        PageService.deleteBlogPost(post).then(response => {
          $this.getList()
          iziToast.success({
            title: "OK",
            message: "Beitrag gelöscht!",
            timeout: 3000,
            position: "topRight",
            transitionIn: "fadeInLeft"
          });
        })
      }, function () {
      //
      });
    }
  }
}
</script>
